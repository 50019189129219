import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment.prod';
import { map } from 'rxjs/operators';
import { DatesSearch } from '../model/datesSearch';

@Injectable({
  providedIn: 'root'
})
export class PagamentoService {

  constructor(private http: HttpClient) { }

  public getAllHolerite(): Observable<any> {
    return this.http.get<any>(`${environment.api}/pagamento/holerite/periodo`);
  }

  public getPeriodoFinanceiro(): Observable<any> {
    return this.http.get<any>(`${environment.api}/pagamento/periodo`);
  }

  public getHoleriteById(id: number, idUsuario: number, dtNow: string, dtPgto: string): Observable<any> {
    let params = new HttpParams()
                    .set('dtNow', dtNow)
                    .set('dataPagamento', dtPgto)
                    .set('idUsuario', idUsuario.toString());

    return this.http.get<any>(`${environment.api}/pagamento/holerite/${id}`, {params: params});
  }

  public getFichaFinanceira(idUsuario: number, anoBase: number): Observable<any> {
    let params = new HttpParams()
                    .set('idUsuario', idUsuario.toString())
                    .set('anoBase', anoBase.toString());

    return this.http.get<any>(`${environment.api}/pagamento/ficha-financeira/extrato`, { params })
  }

  public getDirf(idUsuario: number, anoExercicio: number): Observable<any> {
    let params = new HttpParams()
                    .set('idUsuario', idUsuario.toString())
                    .set('anoExercicio', anoExercicio.toString());

    return this.http.get<any>(`${environment.api}/pagamento/dirf/extrato`, { params })
  }

  public downloadPDF(id: number, parameters: any, dataPagamento: number, valorPlantao: string, idUsuario: number): Observable<any> {

    let params = new HttpParams()
                    .set('dtInit', parameters.dtInit)
                    .set('dtFin', parameters.dtFin)
                    .set('dtNow', parameters.dtNow)
                    .set('dataPagamento', dataPagamento.toString())
                    .set('valorPlantao', valorPlantao.toString())
                    .set('idUsuario', idUsuario.toString())
                    .set('pdf', (true).toString());

    return this.http.get<any>(`${environment.api}/pagamento/holerite/${id}`, { responseType: "blob" as 'json', params: params})
    .pipe(
      map((res: any) => {
        return new Blob([res], { type: 'application/pdf' });
      })
    )
  }

  public getBalanceteFinanceiro(parameters: DatesSearch): Observable<any> {
    let params = new HttpParams()
                    .set('dtInit', parameters.dtInit)
                    .set('dtEnd', parameters.dtFin)
                    .set('dtNow', parameters.dtNow);

    return this.http.get<any>(`${environment.api}/pagamento/balancete-financeiro`, { params });
  }

  public downloadFichaPDF(idUsuario: number, anoBase: number, dtNow: string): Observable<any> {

    let params = new HttpParams()
                    .set('dtNow', dtNow)
                    .set('anoBase', anoBase.toString())
                    .set('idUsuario', idUsuario.toString())
                    .set('pdf', (true).toString());

    return this.http.get<any>(`${environment.api}/pagamento/ficha-financeira/extrato`, { responseType: "blob" as 'json', params: params})
    .pipe(
      map((res: any) => {
        return new Blob([res], { type: 'application/pdf' });
      })
    )
  }

  public downloadDirfPDF(idUsuario: number, anoExercicio: number, dtNow: string): Observable<any> {

    let params = new HttpParams()
                    .set('idUsuario', idUsuario.toString())
                    .set('anoExercicio', anoExercicio.toString())
                    .set('dtNow', dtNow.toString())
                    .set('pdf', (true).toString());

    return this.http.get<any>(`${environment.api}/pagamento/dirf/extrato`, { responseType: "blob" as 'json', params: params})
    .pipe(
      map((res: any) => {
        return new Blob([res], { type: 'application/pdf' });
      })
    )
  }
}
