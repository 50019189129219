import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  profile(id: number): Observable<any> {
    return this.http.get<any>(`${environment.api}/usuario/${id}/profile`);
  }

  editPassword(id: number, senhaAtual: string, novaSenha: string): Observable<any> {
    return this.http.post<any>(`${environment.api}/usuario/${id}/newpassword`, {senhaAtual, novaSenha });
  }

}
